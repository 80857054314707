<form (ngSubmit)="updateBotAPI()" *ngIf="!loading">
  <mat-card class="mat-card-vertical-scroll cart-scroll">
    <mat-card-title>
      <div class="aiconfig-title">
        <div class="aiconfig-title-left">
          <img src="./assets/aiconfig-ai.svg" alt="" />
        </div>
        <div class="aiconfig-title-right">
          <div class="aiconfig-title-titletext">
            <h3 style="padding-right:8px">Configuración del IA BOT</h3>
            <span class="aiconfig-title-toggle">
                <div class="checkbox-wrapper-12">
                  <div class="cbx">
                    <input id="cbx-12" type="checkbox" [(ngModel)]="form.manual" name="manual" (change)="onManualCheckboxChange()"/>
                    <label for="cbx-12"></label>
                    <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                    </svg>
                  </div>
              <!-- Gooey-->
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                  <filter id="goo-12">
                    <fegaussianblur in="SourceGraphic" stddeviation="4" result="blur"></fegaussianblur>
                    <fecolormatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo-12"></fecolormatrix>
                    <feblend in="SourceGraphic" in2="goo-12"></feblend>
                  </filter>
                </defs>
              </svg>
            </div>
            </span>         
            <div class="aiconfig-arrow">
              <img src="./assets/activado-arrow.svg" alt="" />
              <span *ngIf="form.manual">Activado Modo IA</span>
              <span *ngIf="!form.manual">Modo Manual</span>
            </div>
          </div>
          <div class="aiconfig-title-description">
            Vamos a configurar la inteligencia artificial
          </div>
        </div>
        <div class="aiconfig-percent-right">
          <div class="intelligence-level">
            <div class="intelligence-level-title">NIVEL DE INTELIGENCIA</div>
            <div class="intelligence-level-value" 
                 [ngClass]="{
                   'low': intelligenceLevel < 50, 
                   'medium': intelligenceLevel >= 50 && intelligenceLevel <= 70, 
                   'high': intelligenceLevel > 70
                 }">
              {{ intelligenceLevel }}%
            </div>
            <div class="intelligence-level-subtitle">
              <ng-container *ngIf="intelligenceLevel > 70">ALTO NIVEL DE INTELIGENCIA</ng-container>
              <ng-container *ngIf="intelligenceLevel <= 70 && intelligenceLevel >= 50">INTELIGENCIA MEDIA</ng-container>
              <ng-container *ngIf="intelligenceLevel < 50">BAJO NIVEL DE INTELIGENCIA</ng-container>
            </div>
          </div>          
        </div>
      </div>
    </mat-card-title>
    <mat-card-content class="aiconfig-cardoffset" *ngIf="!loading">
      <div class="aiconfig-content-container">
        <div class="aiconfig-leftcol">
          <h3 class="aiconfig-textarea-title">Describe tu negocio y lo que quieres conseguir:</h3>
          <mat-form-field appearance="outline">
            <textarea matInput [(ngModel)]="form.instructions" name="description" rows="6" (input)="onFormChange(); updateIntelligenceScore()" [disabled]="!form.manual"></textarea>
          </mat-form-field>
          <div class="aiconfig-textarea-description">
            <strong>Ejemplo:</strong> Gimnasio y centro de bienestar dedicado a ofrecer programas de entrenamiento personalizado y clases grupales para adultos. Puedo ofrecer 1 clase de prueba gratuita. Quiero conseguir que la gente me contacte y resuelva todas sus dudas y que reserve una clase de prueba gratis.
          </div>
          <div class="toggle-options">
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title><mat-icon>forum</mat-icon>¿Quieres que si alguien no te responde le pueda hacer un seguimiento?</mat-panel-title>
              </mat-expansion-panel-header>
              <span class="aiconfig-accordion-copy">Los seguimientos son recordatorios que se hacen baja el contexto de la conversación. Por ejemplo si alguien no te responde en menos 24horas la IA enviará un recordatorio para incentivar a continuar la conversación.</span>
              <mat-checkbox class="aiconfig-checkbox" [(ngModel)]="form.followUp" name="followUp" (change)="updateIntelligenceScore()" [disabled]="!form.manual">Si activar la función de seguimiento</mat-checkbox>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title><mat-icon>mic</mat-icon>¿Quieres que el bot haga audios?</mat-panel-title>
              </mat-expansion-panel-header>
              <span class="aiconfig-accordion-copy"> La IAbot tiene la capacidad de poder responder con audios y interpretar los audios que se le envian. También de manera aleatoria y natural enriquecer la conversación con audios cortos de manera aleatoria.</span>
              <mat-checkbox class="aiconfig-checkbox" [(ngModel)]="form.respondWithAudio" name="respondWithAudio" (change)="updateIntelligenceScore()" [disabled]="!form.manual">
                Activar la función de responder con audios (si le llegan audios)
                <mat-icon matTooltip="Placeholder Text" matTooltipPosition="above">info</mat-icon>
              </mat-checkbox>
              <mat-checkbox class="aiconfig-checkbox" [(ngModel)]="form.randomAudios" name="audioEnrichment" (change)="updateIntelligenceScore()" [disabled]="!form.manual || !form.respondWithAudio">
                Activar la función de enriquecimiento de audios aleatorios
                <mat-icon matTooltip="Placeholder Text" matTooltipPosition="above">info</mat-icon>
              </mat-checkbox>
              <div class="aiconfig-voice-container">
                <div 
                  *ngFor="let voice of app.voicesId" 
                  class="aiconfig-voice-selection" 
                  [class.active]="form.voiceId === voice.id" 
                  [class.disabled]="!form.respondWithAudio"
                  (click)="selectVoice(voice.id)">
                  <mat-icon>graphic_eq</mat-icon> {{ voice.name }}
                </div>
              </div>
              <audio #audioPlayer hidden></audio>            
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="aiconfig-cardactions" align="end" *ngIf="!loading">
      <button mat-button type="button" (click)="cancel()">Cancelar</button>
      <button *ngIf="form.manual" class="aiconfig-savebutton" mat-raised-button color="primary"  (click)="saveConfig()" ><span>GUARDAR</span></button>
      <button *ngIf="form.manual" class="aiconfig-submitbutton" mat-raised-button color="primary" type="submit">SIGUIENTE<mat-icon>keyboard_arrow_right</mat-icon></button>
    </mat-card-actions>
  </mat-card>
</form>
<div *ngIf="loading" class="loading-container">
  <div class="loading-indicator">
    
    <img src="./assets/loading.gif" alt="Loading..." class="loading-spinner" />
    <p>Estamos personalizando el pipeline para ti. Esto tomará solo unos segundos…</p>
  </div>
</div>