<div class="content" role="main" *ngIf="inited">
    <div class="container" style="margin-top: 50px">
        <mat-toolbar class="toolbar-container-top" style="min-height: 75px;max-height: 75px;">
            <div class="modules-container" style="justify-content: space-between; width: 100%;">
                <div class="module" style="flex-direction: row;">
                    <img src="assets/generatorLogo.png" alt="Logo" style="width: 150px; height: 100%" />
                </div>
                <div class="module" style="flex-direction: row; margin-right: 0px;">
                    <div style="padding: 0 10px">
                        <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                            <!-- Display plan name based on user role -->
                            <span style="font-size: 16px; font-weight: 700; line-height: 16px;">
                                {{ app.role.toLowerCase() === 'proia' ? 'Plan PRO IA' : 'Plan PRO' }}
                            </span>
                            <!-- Show leads limit only for 'proia' role -->
                            <span *ngIf="app.role.toLowerCase() === 'proia'" style="font-size: 20px; font-weight: 400; line-height: 20px;">
                                {{ app.leadsLimit }}
                            </span>
                        </div>
                    </div>
                    <!-- Show conversation counts and progress bar only for 'proia' role -->
                    <div *ngIf="app.role.toLowerCase() === 'proia'" style="padding: 0 10px; display: flex; flex-direction: column; align-items: center;">
                        <span style="font-size: 14px;">
                            {{ app.apiAllLeadsCount }}/{{ app.leadsLimit }} Conversaciones
                        </span>
                        <span style="height: 2px; width: 100%; display: block;"
                            [style.background]="'linear-gradient(to right, red ' + getProgress() + '%, black ' + getProgress() + '%)'">
                        </span>
                        <div *ngIf="showDate" style="text-align: center; margin-top: 5px;">
                            <span style="font-size: 14px;">
                                {{ remainingDays }} día{{ remainingDays !== 1 ? 's' : '' }} restantes
                              </span>
                        </div>                    
                    </div>
                    <button (click)="openSubscriptionModal()" matTooltip="Aumenta tu plan para más funcionalidades" style="border: none; 
                        border-radius: 5px;
                        background: #e3f8fa;
                        display: flex; 
                        align-items: center; 
                        justify-content: center;
                        color: black; 
                        padding: 5px 10px; 
                        cursor: pointer;
                        margin: 0 5px;">
                        <img src="assets/specialForce.svg" alt="Logo" class="custom-icon"
                            style="height: 30px; width: 30px; margin-right: 5px;" />
                        <span style="font-size: 14px;
                        font-weight: 600;">
                            Aumentar Plan
                        </span>
                    </button>
                    <button matTooltip="Conectar para sincronizar datos" style="border: none; 
                        border-radius: 5px;
                        background: #f5e6fe;
                        display: flex; 
                        align-items: center; 
                        justify-content: center;
                        color: white; 
                        padding: 5px; 
                        cursor: pointer;
                        margin: 0 5px;">
                        <img src="assets/url.svg" alt="chain" class="custom-icon" style="height: 30px; width: 100%;"
                            (click)="openConnectionModal()" />
                    </button>
                </div>
            </div>
        </mat-toolbar>
    </div>
    <div class="container" style="margin-top: 50px">
        <mat-toolbar class="toolbar-container" style="min-height: 75px;max-height: 75px; ">
            <div class="modules-container">
                <div style="display: flex; align-items: center;">
                    <div class="module" style="align-items: flex-start">
                        <span>Total leads</span>
                        <div class="module-text" style="padding-bottom: 5px;">
                            <img src="assets/teamLeader.svg" alt="Total leads" class="custom-icon"
                                style="width: 30px; height: 100%; margin-right: 5px;" />
                            <span class="module-number">{{ app.totalLeads }}</span>
                        </div>
                    </div>
                    <div class="module" style="align-items: flex-start">
                        <span>Acumulado</span>
                        <div class="module-text" style="padding-bottom: 5px;">
                            <img src="assets/bag.svg" alt="Acumulado" class="custom-icon"
                                style="width: 30px; height: 100%; margin-right: 5px;" />
                            <span class="module-number">{{ app.totalAmount | number : '1.0-0' }}
                                {{app.selectedSymbol}}</span>
                        </div>
                    </div>
                    <div class="module" style="align-items: flex-start">
                        <span>Ganado</span>
                        <div class="module-text" style="padding-bottom: 5px;">
                            <img src="assets/coin.svg" alt="Ganado" class="custom-icon"
                                style="width: 30px; height: 100%; margin-right: 5px;" />
                            <span class="module-number">{{ app.totalProfit | number : '1.0-0' }}
                                {{app.selectedSymbol}}</span>
                        </div>
                    </div>
                </div>
                <div style="display: flex; align-items: center;">
                    <div *ngIf="app.userId=='sanov57191@kinsef.com' && !stimulation && app.role.toLowerCase() =='proia'" matTooltip="Iniciar Estimulación" class="refresh-container stimulation-container"
                    style="cursor: pointer; margin-right: 10px;" (click)="startStimulation()">
                        <img src="assets/start.svg" alt="Refresh" class="custom-icon left-side-icon" />
                    </div>
                    <div *ngIf="app.userId=='sanov57191@kinsef.com' && stimulation && app.role.toLowerCase() =='proia'" matTooltip="Stop Estimulación" class="refresh-container stop-stimulation-container"
                    style="cursor: pointer; margin-right: 10px;" (click)="stopStimulation()">
                        <img src="assets/stop.svg" alt="Refresh" class="custom-icon left-side-icon" />
                    </div>
                    <div *ngIf="app.userId=='josb3t@gmail.com'" matTooltip="Gestionar Plantillas" class="refresh-container"
                        style="cursor: pointer; margin-right: 10px;" (click)="manageTemplates()"> 
                        <img src="assets/manageTemplates.png" alt="Manage Templates" class="custom-icon left-side-icon" /> 
                    </div>
                    <div matTooltip="Actualizar Conversaciones" class="refresh-container"
                        style="cursor: pointer; margin-right: 10px;" (click)="refreshConversations()">
                        <img src="assets/redo.svg" alt="Refresh" class="custom-icon left-side-icon" />
                    </div>
                    <div matTooltip="Configurar Bot" *ngIf="app.isWhatsappAPIConnected && app.role.toLowerCase() =='proia'" class="api-container"
                        style="cursor: pointer;" (click)="configurateWhatsappApi()">
                        <!-- <img matTooltip="WhatsappApi" src="assets/api.svg" alt="Refresh"
                            class="custom-icon left-side-icon" /> -->
                        <span class="custom-icon left-side-icon"
                            style="display: flex; align-items: center; justify-content: center;">
                            <img src="assets/robotic.svg" alt="Refresh" class="custom-icon left-side-icon" />
                        </span>
                    </div>
                    <div class="currency-container" matTooltip="Divisa">
                        <mat-form-field appearance="fill">
                            <img [src]="selectedFlag" class="currency-flag">
                            <mat-select #currencySelect [(value)]="selectedCurrency" (selectionChange)="onCurrencyChange($event.value)">
                                <mat-option *ngFor="let currency of currencies" [value]="currency.code">
                                    <img [src]="currency.flag" class="currency-flag">
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <span class="header-spacer"></span>
                    <span *ngIf="app.conversationSyncMessage && app.isWhatsappConnected" style="font-size: 14px;
                    font-style: italic;
                    color: #d1d1d1;">Sincronización en curso, seguimos cargando tus conversaciones...</span>

                    <select (change)="onSelectChange($event)"
                        style="margin-left: 10px; padding: 10px 5px; border-radius: 5px; box-shadow: none; background: url('assets/filter.svg') no-repeat 5px center; padding-left: 30px;">
                        <option [value]="''" [selected]="!app.showCheck && !app.showUnread">No filters</option>
                        <option [value]="'modo-privado'" [selected]="app.showCheck">Modo Privado</option>
                        <option [value]="'no-leidos'" [selected]="app.showUnread">No Leídos</option>
                    </select>
                    <input type="text" placeholder="Buscar" (input)="onSearch($event)"
                        style="margin-left: 10px; padding: 10px 5px; border-radius: 5px; box-shadow: none; background: url('assets/search.svg') no-repeat 5px center; padding-left: 30px;">

                </div>
            </div>

            <!-- <div class="right-side-icon-container">
                <div class="currency-container">
                    <mat-form-field appearance="fill">

                        <img [src]="selectedFlag" class="currency-flag"> {{app.selectedSymbol}}
                        <mat-label>Divisa</mat-label>
                        <mat-select #currencySelect [(value)]="selectedCurrency"
                            (selectionChange)="onCurrencyChange($event.value)">
                            <mat-option *ngFor="let currency of currencies" [value]="currency.code">
                                <img [src]="currency.flag" class="currency-flag"> {{ currency.code }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="vertical-line-right"></div>
                <img [src]="app.showCheck ? 'assets/modo-privado.svg' : 'assets/modo-privado-select.svg'"
                    [matTooltip]="app.showCheck ? '' : 'Privado'" alt="Modo Privado" class="right-side-icon"
                    (click)="toggleModoPrivado()" />

                <img [src]="app.showUnread ? 'assets/no-leidos-active.svg' : 'assets/no-leidos-inactive.svg'"
                    [matTooltip]="app.showUnread ? 'Todos' : 'No leídos'" alt="No Leídos" class="right-side-icon"
                    (click)="toggleNoLeidos()" />

                <img src="assets/help.svg" alt="Ayuda" matTooltip="Ayuda" class="right-side-icon"
                    (click)="helpModal()" />
                <img matTooltip="Conexiones" src="assets/manage-accounts.png" class="right-side-icon"
                    (click)="openConnectionModal()" />
            </div> -->
        </mat-toolbar>
    </div>
    <div class="card-container" cdkDropListGroup>
        <ng-container *ngFor="let card of app.cards">
            <app-contact-cart [card]="card" [dropFunction]="drop" [dragFunction]="drag" [createBox]="createBox"
                [editBox]="editBox" [showMainView]="showMainView" [columnLength]="app.cards.length"
                [updatePosition]="updatePosition" [newData]="newData"></app-contact-cart>
        </ng-container>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" [fullScreen]="true"
    template="<img width='50%' src='./assets/loading_white.gif' />"></ngx-spinner>