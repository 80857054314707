<div class="connection-modal-container">
  <div class="connection-modal">
    <div>
    <button mat-icon-button class="close-button" (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </button>    

    <div class="connection-modal-div-1">
      <h3 class="connection-modal-text">Administra tus redes</h3>
    </div>
  <div class="alert-div"><p class="alert-p">Esta opción es inestable al no ser una funcionalidad oficial de Meta. Recomendamos usar WhatsApp API</p></div>
    <div class="service-container first-service">
      <div class="service whatsapp">
        <img class="connection-icon" src="assets/whatsapp-icon.svg"/>
        <span class="service-name">WhatsApp Web  <span class="noofficial-span">NO OFICIAL</span></span> 
        <button class="service-btn" (click)="toggleWhatsAppConnection()">
          {{ app.isWhatsappConnected ? 'Desconectar' : 'Conectar' }}
        </button>
      </div>

      <div class="whatsapp-accordion-content" [class.show]="showWhatsappAccordion">
        <div class="qrcode">
          <div class="qrcode-text">
            <div>
              <h3>Cómo conectar tu cuenta de WhatsApp</h3>
              <p>1. Abre WhatsApp en tu móvil</p>
              <p>2. Pulsa sobre Menú o Ajustes y selecciona Dispositivos Enlazados</p>
              <p>3. Apunta con el móvil hacia la pantalla para escanear el QR</p>
              <p>4. Si al escanear el QR aparece este mensaje: "En este momento no se pueden vincular nuevos dispositivos" por favor clica en el botón de la flecha que hay arriba del QR para actualizarlo y escanearlo de nuevo</p>
              <p>5. Tus conversaciones pueden tardar unos minutos en aparecer en el PIPELINE</p>
            </div>
          </div>
          <div class="qrimage-container">
            <div *ngIf="qrdata !== ''; else loading">
              <div style="display:flex; justify-content: space-around;">
                  <span (click)="changeQrCode()" matTooltip="Actualizar QR" matTooltipPosition="above" class="refreshqr-icon">
                    <img src="./assets/refresh_icon_b.png" />
                  </span>
              </div>
              <img [src]="qrdata"/>
            </div>
            <ng-template #loading>
              <div class="loading-indicator">
                  Insert your loading spinner or GIF here
                  <img src="./assets/loading.gif" alt="Loading..." style="width: 110px; height: 110px;"/>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="service-container">
      <div class="service whatsapp">
        <img class="connection-icon" src="assets/whatsappbusiness.png"/>
        <span class="service-name">WhatsApp API   <span class="official-span">OFICIAL</span><span *ngIf="app.role.toLowerCase() == 'proia'" class="proia-span">PRO IA</span></span>
        <div class="ver-video-container">
            <span class="ver-video-label" (click)="openVideoModal()">
                <img src="assets/eye-icon.svg" alt="Icon" class="icon-img"> Ver vídeo
            </span>
        </div>
        <button class="service-btn" (click)="toggleWhatsAppApiConnection()">
          {{ app.isWhatsappAPIConnected ? 'Desconectar' : 'Conectar' }}
        </button>
      </div>
      <div *ngIf="api.userId=='josb3t@gmail.com'" class="instagram-accordion-content" [class.show]="app.showWhatsappApiAccordion">
        <div class="pages-container">
            <div class="facebook-pages">
                <h3>Whatsapp Numbers</h3>
                <mat-form-field class="custom-mat-form-field" appearance="fill">
                  <mat-label>Choose a number</mat-label>
                  <mat-select [(value)]="app.selectedPhoneNumberId" (selectionChange)="onPhoneNumberChange($event)">
                    <mat-option *ngFor="let number of app.phoneNumbers" [value]="number.id">
                        {{number.verified_name}} : {{number.display_phone_number}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>  
            </div>
        </div>
      </div>
    </div>

    <div class="service-container">
      <div class="service instagram">
        <img  class="connection-icon" src="assets/facebook-icon.png"/>
        <span class="service-name">Facebook  <span class="official-span">OFICIAL</span></span>
        <button *ngIf="!instaIsLoading" class="service-btn" (click)="toggleInstagramConnection()">
          {{ app.isFacebookConnected || app.isInstagramConnected ? 'Desconectar' : 'Conectar' }}
        </button>
        <img *ngIf="instaIsLoading" class="success-icon" src="./assets/loading.gif" alt="Loading..." style="width: 55px; height: 55px;"/>
      </div>
      <div class="instagram-accordion-content" [class.show]="showInstagramAccordion">
        <div class="pages-container">
            <div class="facebook-pages">
                <h3 style="text-align: center;">Pages</h3>
                <mat-form-field class="custom-mat-form-field" appearance="fill">
                  <mat-label>Choose a page</mat-label>
                  <mat-select [(value)]="selectedPageId" (selectionChange)="onPageChange($event)">
                    <mat-option *ngFor="let page of pages" [value]="page.pageId">
                        Facebook : {{ page.pageName + (page.inclusInstagram ? ' / Instagram : ' + page.instagramName : '') }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
        </div>
      </div>
    </div>

    <!--<div class="instagram-accordion-content" [class.show]="showInstagramAccordion">
      <div class="qrcode">
        <div class="qrcode-text">
          <div>
            <h3>Cómo conectar tu cuenta de WhatsApp</h3>
            <p>1. Abre WhatsApp en tu móvil</p>
            <p>2. Pulsa sobre Menú o Ajustes y selecciona Dispositivos Enlazados</p>
            <p>3. Apunta con el móvil hacia la pantalla para escanear el QR</p>
          </div>
        </div>
        <div class="qrimage-container">
        <img *ngIf="qrdata !== ''; else loading" [src]="qrdata"/>
        <ng-template #loading>
            <div class="loading-indicator">
                <img src="./assets/loading.gif" alt="Loading..." style="width: 110px; height: 110px;"/>
            </div>
        </ng-template>
        </div>
      </div>
    </div>
    -->
  </div>
</div>
</div>