import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { ApiService } from '../shared/api.service';
import { AppService } from '../app.service';

import { Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class SignalRService {
  private hubConnection: signalR.HubConnection | null = null;
  public messageReceived = new Subject<any>();
  public wonUpdated = new Subject<any>();
  public leadsAmountUpdated = new Subject<any>();
  public groupingUpdated = new Subject<any>();
  public leadMoved = new Subject<any>();
  public instagramConnectionStatus = new Subject<boolean>();
  public instagramNewMessage = new Subject<any>();
  public facebookNewMessage = new Subject<any>();
  public rescheduledSended = new Subject<any>();
  public messageApiReceived = new Subject<any>();
  public botSucceed = new Subject<any>();

  constructor(private apiService: ApiService,private app: AppService) {}

  public startConnection(): void {
      this.createConnection();
      this.startHubConnection();
  }
  

  private addToUserGroup(key: string): void {
    if (this.hubConnection && key) {
      this.hubConnection.invoke('AddToUserGroup', key)
        .then(() => console.log(`Added to user group: ${key}`))
        .catch(err => console.error('Could not add to user group:', err));
    }
  }

  private createConnection(): void {
    if (this.hubConnection === null) {
      var websocketUrl = 'https://generator-pipeline-api.azurewebsites.net/test';
      if(this.apiService.userId=="souhaib.ahar@gmail.com" || this.apiService.userId==  "josb3t@gmail.com")
      {
        websocketUrl = 'https://generator-pipeline-api-test.azurewebsites.net/test';
      }
      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(websocketUrl)
      .build();
    }
  }

  private startHubConnection(): void {
    if (this.hubConnection) {
      this.hubConnection
        .start()
        .then(() => {
          
          this.addToUserGroup(this.apiService.userKey);
        })
        .catch(err => {
          
        });
  
      this.hubConnection.onclose(err => {
        
        setTimeout(() => this.startHubConnection(), 3000);
      });
    }
  }

  public addMessageListener(): void {
    
    if (this.hubConnection) {
      this.hubConnection.on('ReceiveMessage', (data) => {
          this.messageReceived.next(data);
      });
    } else {
      console.error('Hub connection not started.');
    }
  }

  public addWonListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('WonUpdated', (data) => {
          this.wonUpdated.next(data);
      });
    } else {
      console.error('Hub connection not started.');
    }
  }

  public addLeadsAmountListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('TotalLeadsAmount', (data) => {
          this.leadsAmountUpdated.next(data);
      });
    } else {
      console.error('Hub connection not started.');

      
    }
  }

  public addGroupUpdateListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('GroupingUpdated', (data) => {
          this.groupingUpdated.next(data);
      });
    } else {
      console.error('Hub connection not started.');
    }
  }

  public addLeadMovesListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('LeadMoves', (data) => {
          
          this.leadMoved.next(data);
      });
    } else {
      console.error('Hub connection not started.');
    }
  }
  public updateInstagramStatus(): void {
    if (this.hubConnection) {
      this.hubConnection.on('InstagramLoginStatus', (data) => {
        this.app.isFacebookConnected = data;
        this.instagramConnectionStatus.next(data);
      });
    } else {
      console.error('Hub connection not started.');
    }
  }

  public addNewInstaMessageListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('InstagramMessage', (data) => {
          this.instagramNewMessage.next(data);
      });
    } else {
      console.error('Hub connection not started.');
    }
  }
  public addNewFacebookMessageListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('FacebookMessage', (data) => {
          this.facebookNewMessage.next(data);
      });
    } else {
      console.error('Hub connection not started.');
    }


    
  }

  public addRemoveRescueduledListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('DeleteRescheduled', (data) => {
          this.rescheduledSended.next(data);
      });
    } else {
      console.error('Hub connection not started.');
    }
  }

  public addRefreshListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('RefreshClient', (data) => {
        localStorage.clear();
        window.location.reload();
      });
    } else {
      console.error('Hub connection not started.');
    }
  }

  public addApiListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('ReceiveApiMessage', (data) => {
        this.messageApiReceived.next(data);
        
      });
    } else {
      console.error('Hub connection not started.');
    }
  }

  public addHandleSuccessListener(): void {
    if (this.hubConnection) {
      this.hubConnection.on('HandleSuccessBot', (data) => {
        this.botSucceed.next(data);
      });
    } else {
      console.error('Hub connection not started.');
    }
  }
}