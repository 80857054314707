<div class="manage-templates-container">
  <!-- Left Panel: Templates List -->
  <div class="left-panel">
    <h2>Manage WhatsApp API Templates</h2>
    <button class="create-template-btn" (click)="openTemplateForm()">
      + Create New Template
    </button>

    <div class="template-list">
      <ng-container *ngIf="app.templates.length > 0; else noTemplates">
        <h3>Your Templates</h3>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let template of app.templates; let i = index">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ template.name }} ({{ template.language }})
              </mat-panel-title>
              <mat-panel-description>
                Category: {{ template.category }}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="template-preview">
              <strong>Body:</strong>
              <p>{{ template.components[0]?.text }}</p>

              <strong>Status:</strong>
              <p>{{ template.status }}</p>
              <button mat-button color="accent" (click)="checkStatus(template)">
                Check Status
              </button>
            </div>

            <div class="template-actions">
              <button mat-button color="primary" (click)="editTemplate(i)">
                Edit
              </button>
              <button mat-button color="warn" (click)="deleteTemplate(i)">
                Delete
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>

      <ng-template #noTemplates>
        <p>No templates found. Click "Create New Template" to add one!</p>
      </ng-template>
    </div>
  </div>

  <!-- Right Panel: Template Form -->
  <div class="right-panel" *ngIf="isTemplateFormVisible">
    <div class="template-form-dialog">
      <h3 *ngIf="editingTemplateIndex === -1">Create New Template</h3>
      <h3 *ngIf="editingTemplateIndex !== -1">Edit Template</h3>

      <form (submit)="saveTemplate(); $event.preventDefault()">
        <!-- Name -->
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="templateForm.name" name="name" required />
        </mat-form-field>

        <!-- Language (restricted to English, French, Spanish) -->
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Language</mat-label>
          <mat-select
            [(value)]="templateForm.language"
            [(ngModel)]="templateForm.language"
            name="language"
            required
          >
            <mat-option *ngFor="let lang of languageOptions" [value]="lang">
              {{ lang }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Category -->
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Category</mat-label>
          <input
            matInput
            [(ngModel)]="templateForm.category"
            name="category"
            required
          />
        </mat-form-field>

        <!-- Components (Body) -->
        <div class="components-section">
          <h4>Components</h4>
          <div
            class="component"
            *ngFor="let component of templateForm.components; let i = index"
          >
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Body</mat-label>
              <textarea
                matInput
                [(ngModel)]="templateForm.components[i].text"
                name="component-{{ i }}"
                required
              ></textarea>
            </mat-form-field>

            <!-- Remove Component Button -->
            <button
              mat-mini-fab
              color="warn"
              *ngIf="templateForm.components.length > 1"
              (click)="removeComponent(i)"
            >
              -
            </button>
          </div>
        </div>

        <!-- Form Actions -->
        <div class="form-actions">
          <button mat-button color="primary" type="submit">Save</button>
          <button mat-button (click)="closeTemplateForm()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>