import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

interface ComponentDetails {
  text: string;
  type: string;
}

interface Template {
  name: string;
  language: string;
  category: string;
  status: string;
  components: ComponentDetails[];
}

@Component({
  selector: 'app-manage-templates',
  templateUrl: './manage-templates.component.html',
  styleUrls: ['./manage-templates.component.scss'],
})
export class ManageTemplatesComponent implements OnInit {
  templateForm: Template = this.createEmptyTemplate();
  editingTemplateIndex: number = -1;
  isTemplateFormVisible: boolean = false;

  // Language options
  languageOptions = ['English', 'French', 'Spanish'];

  constructor(public app: AppService) {}

  ngOnInit(): void {}

  createEmptyTemplate(): Template {
    return {
      name: '',
      language: '',
      category: '',
      status: 'pending', // Default status
      components: [{ text: '', type: 'BODY' }],
    };
  }

  openTemplateForm(templateIndex: number = -1): void {
    if (templateIndex === -1) {
      this.templateForm = this.createEmptyTemplate();
    } else {
      this.templateForm = JSON.parse(JSON.stringify(this.app.templates[templateIndex]));
    }
    this.editingTemplateIndex = templateIndex;
    this.isTemplateFormVisible = true;
  }

  saveTemplate(): void {
    if (this.editingTemplateIndex === -1) {
      this.app.templates.push({ ...this.templateForm });
    } else {
      this.app.templates[this.editingTemplateIndex] = { ...this.templateForm };
    }
    this.closeTemplateForm();
  }

  deleteTemplate(index: number): void {
    this.app.templates.splice(index, 1);
  }

  editTemplate(index: number): void {
    this.openTemplateForm(index);
  }

  removeComponent(index: number): void {
    this.templateForm.components.splice(index, 1);
  }

  closeTemplateForm(): void {
    this.templateForm = this.createEmptyTemplate();
    this.editingTemplateIndex = -1;
    this.isTemplateFormVisible = false;
  }

  checkStatus(template: Template): void {
    // Call a service or perform an API request here to fetch the status
    console.log('Checking status for:', template.name);
    template.status = 'updated-status'; // Simulate dynamic update
  }
}