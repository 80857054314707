<div id="custom-dialog-content">
  <h1 mat-dialog-title class="custom-title">
    <mat-icon>warning</mat-icon> {{ data.title || 'Confirmación' }}
  </h1>
  <div mat-dialog-content class="custom-dialog-body">
    <p class="custom-message">{{ data.message }}</p>
  </div>
  <div mat-dialog-actions class="custom-dialog-actions">
    <button mat-button class="custom-button cancel-button" (click)="onCancel()">Cancelar</button>
    <button mat-raised-button color="warn" class="custom-button confirm-button" (click)="onConfirm()">
      <mat-icon>check</mat-icon> Confirmar
    </button>
  </div>
</div>
