import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BotFinalizeModalComponent } from '../bot-finalize-modal/bot-finalize-modal.component';
import { ApiService } from '../shared/api.service';

@Component({
  selector: 'app-column-config-modal',
  templateUrl: './column-config-modal.component.html',
  styleUrls: ['./column-config-modal.component.scss']
})
export class ColumnConfigModalComponent implements OnInit {
  configColumns: { title: string, description: string }[] = [];

  constructor(
    private dialog: MatDialog,
    public api: ApiService,
    public dialogRef: MatDialogRef<ColumnConfigModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.columnSuggestions && this.data.columnSuggestions.funnel_steps) {
      this.configColumns = this.data.columnSuggestions.funnel_steps;
      
    }
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // Method to add a new column
  addColumn(): void {
    this.configColumns.push({ title: '', description: '' });
  }

  // Method to remove a column
  removeColumn(index: number): void {
    this.configColumns.splice(index, 1);
  }

  // Method to open the finalize modal
  openFinalizeModal(): void {
    this.api.sendPostRequest({
      action: 'Action/ChangePipeGrouping',
      body: this.configColumns
    }).subscribe({
      next: (res) => {
        
      },
      error: (error) => {
        console.error('Failed to update', error);
        alert('Error al actualizar la configuración');
      }
    });
    this.dialogRef.close();
    setTimeout(() => {
      
      this.dialog.open(BotFinalizeModalComponent, {
        width: '400px',
        data: { configColumns: this.configColumns }
      });
    }, 2000);
  }
}